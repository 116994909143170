import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import SanityImage from 'gatsby-plugin-sanity-image';

const SingleProjectStyles = styled.div`
  margin: 1rem 0;
  max-width: 800px;
  a {
    display: grid;
    grid-template-rows: 400px auto auto;
    grid-row: span 3;

    padding: 2rem;

    &:hover {
      background-color: var(--slate);
    }
  }

  h2 {
    /* margin: 1rem; */
    font-size: 2.2rem;
    text-align: left;
    margin: 0;
  }

  img {
    max-width: 800px;
  }

  .title {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    align-items: center;
    margin: 1rem;
  }

  .role {
    color: grey;
    font-weight: 300;
    display: flex;
    align-items: baseline;
    justify-content: right;
    gap: 1rem;
    font-size: 1.2rem;
    margin: 0;
  }
  .role span {
    color: white;
    font-size: 1.5rem;
  }

  .categories {
    justify-self: left;
    display: flex;
    align-items: center;
  }
  .categories h3 {
    background-image: var(--secondary-grad);
    padding: 1rem 2rem;
    font-size: 1.4rem;
    border-radius: 3rem;
    margin: 0.5rem;
  }
`;

export const CategoryStyles = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;

  h3 {
    font-weight: 300;
    color: black;
  }
`;

function SingleProject({ project }) {
  return (
    <SingleProjectStyles>
      <Link to={`/${project.slug.current}`} className="rounded">
        <SanityImage
          {...project.image}
          className="hero-img pixelated-load rounded shadow"
        />
        <div className="title">
          <h2>{project.name}</h2>
          <div className="projectInfo">
            <h5 className="role">
              Client: <span>{project.client}</span>
            </h5>
            <h5 className="role">
              Role: <span>{project.role}</span>
            </h5>
          </div>
        </div>
        <div className="categories">
          {project.categories.map((category) => (
            <CategoryStyles>
              <h3>{category.name}</h3>
            </CategoryStyles>
          ))}
        </div>
      </Link>
    </SingleProjectStyles>
  );
}

const ProjectStyles = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
`;

export default function projectList({ projects }) {
  const sortedProjects = projects.sort(
    (a, b) => new Date(b.datePublished) - new Date(a.datePublished)
  );
  return (
    <ProjectStyles>
      {sortedProjects.map((project) => (
        <SingleProject key={project.id} project={project} />
      ))}
    </ProjectStyles>
  );
}
