import { graphql } from 'gatsby';
import React from 'react';
import ProjectsCategoriesFilter from '../components/ProjectsCategoriesFilter';
import ProjectList from '../components/ProjectList';
import SEO from '../components/SEO';

export default function ProjectsPage({ data, pageContext }) {
  const projects = data.projects.nodes;
  return (
    <>
      <SEO
        title={pageContext.name ? `${pageContext.name} - Projects` : `Projects`}
      />
      <h1>Projects</h1>
      <ProjectsCategoriesFilter />
      <ProjectList projects={projects} />
    </>
  );
}

export const query = graphql`
  query ProjectQuery($slug: String) {
    projects: allSanityProject(
      filter: {
        categories: { elemMatch: { slug: { current: { eq: $slug } } } }
      }
    ) {
      nodes {
        name
        id
        slug {
          current
        }
        datePublished
        categories {
          name
          slug {
            current
          }
        }
        image {
          ...ImageWithPreview
          alt
          asset {
            fluid(maxWidth: 800) {
              ...GatsbySanityImageFluid
            }
          }
        }
        role
        client
        content {
          children {
            text
            marks
            _type
            _key
          }
          _type
        }
      }
      totalCount
    }
  }
`;
